export const durations = [
    {
        key: -1,
        text: "Last week"
    },
    {
        key: 0,
        text: "This week"
    },
    {
        key: 1,
        text: "Next week"
    },
    {
        key: 2,
        text: "In 2 weeks"
    },
    {
        key: 3,
        text: "In 3 weeks"
    },
    {
        key: 4,
        text: "In 4 weeks"
    },
    {
        key: 5,
        text: "In 5 weeks"
    },
    {
        key: 6,
        text: "6 Weeks or More"
    },
];

export const weekTitles = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const dates = (startDate, num) => Array.from(
    { length: num },
    (_, i) => new Date(startDate.getTime() + (i * 60000 * 60 * 24)).toISOString().slice(0, 10)
);
  
export const getDaysOfWeek = (index = 0, DayDuration = 7) => {
    let week = (parseInt(index) * 7);
    let date = new Date();
    date.setDate(date.getDate() - date.getDay() + week);
    return dates(date, DayDuration);
}