import React, { useEffect, useRef, useState } from 'react'
import { Switch, Spin, notification } from 'antd';
import { Chart } from "react-google-charts";
import { connect } from 'react-redux';
import moment from 'moment'
import Segmented from '../components/Segmented'
import { getCompletedStates, getDashboardStatusStates, getFeedbackCategory, getSalesChartData, getScheduledStatesData, submitFeedback } from '../store/dashboard/action';
import TitleWithSelection from '../components/TitleWithSelection';
import SetDefaultPage from '../components/SetDefaultPage';
import { formatNumber } from '../helpers/stringFormat';
import { initializePusher, unbindPusher } from '../pusherManager';

const options = {
    title: "",
    legend: { position: "bottom" },
    pointSize: 6,
    colors: ["#006EFF", "#97C3FF"],
    lineWidth: 2,
};

const Dashboard = (props) => {
    const { statusState, completedStates, scheduledStates, feedback, salesChart, feedbackCategory,
        getDashboardStatusStates, getCompletedStates, getScheduledStatesData, getSalesChartData, getFeedbackCategory, onSubmitFeedback } = props
    const fileInputRef = useRef(null);
    const scrollRef = useRef(null);

    const [isComparison, setComparison] = useState(false)
    const [selecetdScheduleJob, setSelectedScheduleJob] = useState('this_week')
    const [selecetdCompletedJob, setSelectedCompletedJob] = useState('week_to_date')
    const [selecetdSales, setSelectedSales] = useState('this_week')
    const [feedback_category, setFeedbackCategory] = useState('')
    const [feedback_msg, setFeedbackMsg] = useState('')
    const [chartData, setChartData] = useState([])
    const [files, setFiles] = useState([])
    const [submitted, setSubmitted] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        getDashboardStatusStates()
        getScheduledStatesData(selecetdScheduleJob)
        getCompletedStates(selecetdCompletedJob)
        getFeedbackCategory()
        getSalesChartData(selecetdSales)
    }, [])

    useEffect(() => {
        initializePusher("organizations", "choose-event", (data) => {
            if (data.event === "selected" && data?.orgId) {
                getDashboardStatusStates()
                getScheduledStatesData(selecetdScheduleJob)
                getCompletedStates(selecetdCompletedJob)
                getFeedbackCategory()
                getSalesChartData(selecetdSales)
            }
        })

        return () => {
            unbindPusher("organizations", "choose-event");
        };
    }, [])

    useEffect(() => {
        if (feedback.submitted) {
            setSubmitted(true);
        }
    }, [feedback.submitted])

    useEffect(() => {
        if (submitted) {
            const timeout = setTimeout(() => setSubmitted(false), 120000); // 2 minutes
            return () => clearTimeout(timeout);
        }
    }, [submitted]);

    useEffect(() => {
        if (Object.keys(salesChart?.data)?.length > 0) {
            const data = []
            data.push(["Year", "Sales"])
            for (var i = 0; i < Object.keys(salesChart.data).length; i++) {
                const key = Object.keys(salesChart.data)[i]
                const value = Object.values(salesChart.data)[i]
                const row = [key, value]
                data.push(row)
            }
            setChartData(data)
        }
    }, [salesChart])

    const handleSubmit = () => {
        try {
            if (!feedback_category) {
                notification.open({ type: 'error', message: 'Please select feedback type.' })
                return
            }
            if (!feedback_msg) {
                notification.open({ type: 'error', message: 'Feedback message is required.' })
                return
            }
            const payload = { category: feedback_category, feedback: feedback_msg }
            onSubmitFeedback(payload)
        } catch (error) {
            console.log(error)
        }
    }
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setFiles(file);
        }
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Adjust speed of scroll
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <div className="dashboard">
            <div className="header pb-3">
                <TitleWithSelection title='Dashboard' >
                    <SetDefaultPage routeName='/dashboard' checked={localStorage.getItem('defaultLandingPage') === '/dashboard'} />
                </TitleWithSelection>
            </div>
            <Spin spinning={statusState.loading}>
                <div className='dashboard-container'>
                    {/* status timeline */}
                    <div className='status-container'>
                        <label className='label-status'>STATUSES</label>
                        <div
                            ref={scrollRef}
                            className='timeline-container'
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseUp}
                            onMouseUp={handleMouseUp}
                        >
                            <div className='timeline-line' />

                            {statusState?.data?.map((item, index) => {
                                return (
                                    <div className='status-item' key={index}>
                                        <label className='status-name'>{item.status_name}</label>
                                        <div className='status'>
                                            <div className='round'>
                                                <div className='round-value' style={{
                                                    height: item.total_count === 0 ? 20 : 10 * item.total_count,
                                                    width: item.total_count === 0 ? 20 : 10 * item.total_count,
                                                    opacity: item.total_count === 0 ? 0 : 1
                                                }}>
                                                    <span>{item.total_count !== 0 ? item.total_count : ''}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='botton-value'>
                                            <div className='value'>
                                                <img alt="" src={require('../assets/images/dollar.svg').default} />
                                                <span>{formatNumber(item.total_value, 2)}</span>
                                            </div>
                                            <div className='value'>
                                                <img alt="" src={require('../assets/images/clock_icon.svg').default} />
                                                <span>{item.total_hour}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    {/* status card */}
                    <div className='status-cards'>
                        {/* schedule jobs */}
                        <Spin spinning={scheduledStates.loading && !statusState.loading}>
                            <div className='card'>
                                <Segmented
                                    options={[{ label: 'This Week', slug: 'this_week' }, { label: 'Next Week', slug: 'next_week' }, { label: 'In 2 Weeks', slug: 'in_two_weeks' }, { label: 'In 3 Weeks', slug: 'in_three_weeks' }]}
                                    selected={selecetdScheduleJob}
                                    onChange={(value) => {
                                        getScheduledStatesData(value)
                                        setSelectedScheduleJob(value)
                                    }}
                                />
                                <div className='w-100 gap-2 d-flex flex-column'>
                                    <div className='details'>
                                        <label>Jobs scheduled</label>
                                        <h6>{scheduledStates?.data?.total_scheduled_job ?? 0}</h6>
                                    </div>
                                    <div className='details'>
                                        <label>Hours scheduled (total)</label>
                                        <h6>{scheduledStates?.data?.total_hours ?? 0}</h6>
                                    </div>
                                </div>
                            </div>
                        </Spin>

                        {/* completed jobs */}
                        <Spin spinning={completedStates.loading && !statusState.loading}>
                            <div className='card'>
                                <Segmented
                                    options={[{ label: 'Week to Date', slug: 'week_to_date' }, { label: 'This Month to Date', slug: 'month_to_date' }, { label: 'Year to Date', slug: 'year_to_date' }]}
                                    selected={selecetdCompletedJob}
                                    onChange={(value) => {
                                        setSelectedCompletedJob(value)
                                        getCompletedStates(value)
                                    }}
                                />
                                <div className='w-100 gap-2 d-flex flex-column'>
                                    <div className='details'>
                                        <label>Jobs Completed</label>
                                        <h6>{completedStates?.data?.total_completed_job ?? 0}</h6>
                                    </div>
                                    <div className='details'>
                                        <label>Total Value</label>
                                        <h6>{formatNumber(completedStates?.data?.total_amount, 2, '$') ?? 0}</h6>
                                    </div>
                                </div>
                            </div>
                        </Spin>

                        {submitted ?
                            <div className='card-thank-you'>
                                <h5>Thank you <br />for your feedback!</h5>
                            </div>
                            :
                            <div className='card-attachment'>
                                <label className='attach-label'>SEND US YOUR FEEDBACK</label>
                                <Segmented
                                    options={Object.keys(feedbackCategory?.data)?.length > 0 ? feedbackCategory?.data : []}
                                    selected={feedback_category}
                                    onChange={(value) => setFeedbackCategory(value)}
                                />
                                <div className='feedback-input'>
                                    <input
                                        placeholder='Type your message'
                                        onChange={(e) => setFeedbackMsg(e.target.value)}
                                    />
                                    <img alt="" src={require('../assets/images/input-icon.svg').default} />
                                </div>
                                <div className='attach-row'>
                                    {/* <div className='d-flex flex-row gap-2 align-items-center cursor-pointer' onClick={() => fileInputRef.current?.click()} >
                                        <input
                                            ref={fileInputRef}
                                            type="file"
                                            accept=".jpg,.png,.pdf,.doc,.docx,.txt,.rtf"
                                            style={{ display: "none" }}
                                            onChange={handleFileChange}
                                        />
                                        <label className='file-label'>Attach File</label>
                                        <img className='file-icon' alt="" src={require('../assets/images/attachment.svg').default} />
                                    </div> */}
                                    {feedback?.loading ?
                                        <div className='btn-send'>
                                            <div class="spinner" />
                                            <span>Sending</span>
                                        </div>
                                        :
                                        <div className='btn-send' onClick={handleSubmit}>
                                            <span>Send</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>

                    {/* sales */}
                    <Spin spinning={!statusState.loading && salesChart.loading}>
                        <div className='sales'>
                            <div className='sales-header'>
                                <label className='label-sales'>Sales</label>
                                <div className='right-content'>
                                    {isComparison ?
                                        <div className='multi-segmented-control'>
                                            <Segmented
                                                options={[{ label: 'This Week', slug: 'this_week' }, { label: 'Last Week', slug: 'last_week' }]}
                                                selected={selecetdSales}
                                                onChange={(value) => {
                                                    setSelectedSales(value)
                                                    getSalesChartData(value)
                                                }}
                                                comparison={selecetdSales === "this_week" || selecetdSales === 'last_week'}
                                            />
                                            <Segmented
                                                options={[{ label: 'This Month', slug: 'this_month' }, { label: 'Last Month', slug: 'last_month' }]}
                                                selected={selecetdSales}
                                                onChange={(value) => {
                                                    setSelectedSales(value)
                                                    getSalesChartData(value)
                                                }}
                                                comparison={selecetdSales === "this_month" || selecetdSales === 'last_month'}
                                            />
                                            <Segmented
                                                options={[{ label: 'This Year', slug: moment().year() }, { label: 'Last Year', slug: moment().subtract("year", 1).year() }]}
                                                selected={selecetdSales}
                                                onChange={(value) => {
                                                    setSelectedSales(value)
                                                    getSalesChartData(value)
                                                }}
                                                comparison={selecetdSales === moment().year() || selecetdSales === moment().subtract("year", 1).year()}
                                            />
                                        </div>
                                        : <Segmented
                                            options={[{ label: 'This Week', slug: 'this_week' }, { label: 'Last Week', slug: 'last_week' }, { label: 'This Month', slug: 'this_month' }, { label: 'Last Month', slug: 'last_month' }, { label: 'This Year', slug: moment().year() }, { label: 'Last Year', slug: moment().subtract("year", 1).year() }]}
                                            selected={selecetdSales}
                                            onChange={(value) => {
                                                setSelectedSales(value)
                                                getSalesChartData(value)
                                            }}
                                        />
                                    }
                                    <div className='gap-2 d-flex flex-row align-items-center'>
                                        <span className='comparison-label'>Comparison to</span>
                                        <Switch defaultChecked onChange={(e) => setComparison(e)} checked={isComparison} />
                                    </div>
                                </div>
                            </div>
                            {chartData?.length > 0 &&
                                <div className='chart-container'>
                                    <Chart
                                        chartType="LineChart"
                                        width="100%"
                                        height="400px"
                                        data={chartData}
                                        options={options}
                                        legendToggle
                                    />
                                </div>
                            }
                        </div>
                    </Spin>
                </div>
            </Spin>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        statusState: state.DashboardReducer.statusState,
        completedStates: state.DashboardReducer.completedStates,
        scheduledStates: state.DashboardReducer.scheduledStates,
        feedback: state.DashboardReducer.feedback,
        salesChart: state.DashboardReducer.salesChart,
        feedbackCategory: state.DashboardReducer.feedbackCategory
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDashboardStatusStates: () => dispatch(getDashboardStatusStates()),
        getCompletedStates: (filter) => dispatch(getCompletedStates(filter)),
        getScheduledStatesData: (filter) => dispatch(getScheduledStatesData(filter)),
        getSalesChartData: (year) => dispatch(getSalesChartData(year)),
        getFeedbackCategory: () => dispatch(getFeedbackCategory()),
        onSubmitFeedback: (payload) => dispatch(submitFeedback(payload))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)