import { all, fork } from 'redux-saga/effects'
import JobSaga from './job/saga'
import UserSaga from './user/saga'
import NoteSaga from './note/saga'
import DashboardSaga from './dashboard/saga';

export default function* rootSaga() {
  yield all([
    fork(JobSaga),
    fork(UserSaga),
    fork(NoteSaga),
    fork(DashboardSaga)
  ]);
}