import { combineReducers } from 'redux'
import JobReducer from './job/reducer'
import UserReducer from './user/reducer'
import NoteReducer from './note/reducer'
import DashboardReducer from './dashboard/reducer';

const rootReducer = combineReducers({
    JobReducer,
    UserReducer,
    NoteReducer,
    DashboardReducer
});

export default rootReducer