import { call, put, takeLatest } from "redux-saga/effects"
import { GET_COMPLETED_STATES, GET_FEEDBACK_CATEGORY, GET_SALES_DATA, GET_SCHEDULED_STATES, GET_STATUS_STATE, SUBMIT_FEEDBACK } from "./actionTypes"
import { getCompletedStates, getFeedbackCategory, getSales, getScheduledStates, getStatusState, submitFeedback } from "../../services/dashboard";
import { getCompletedStatesFailed, getCompletedStatesSuccess, getFeedbackCategoryFailed, getFeedbackCategorySuccess, getSalesChartFailed, getSalesChartSuccess, getScheduledStatesFailed, getScheduledStatesSuccess, getStatusStateFailed, getStatusStateSuccess, submitFeedbackFailed, submitFeedbackSuccess } from "./action";

function* onGetStatusState() {
    try {
        const response = yield call(getStatusState);
        yield put(getStatusStateSuccess(response));
    } catch (error) {
        yield put(getStatusStateFailed(error.response));
    }
}

function* onCompletedStates(data) {
    try {
        const response = yield call(getCompletedStates, data);
        yield put(getCompletedStatesSuccess(response));
    } catch (error) {
        yield put(getCompletedStatesFailed(error.response));
    }
}

function* onScheduledStates(data) {
    try {
        const response = yield call(getScheduledStates, data);
        yield put(getScheduledStatesSuccess(response));
    } catch (error) {
        yield put(getScheduledStatesFailed(error.response));
    }
}

function* onSalesState(data) {
    try {
        const response = yield call(getSales, data);
        yield put(getSalesChartSuccess(response));
    } catch (error) {
        yield put(getSalesChartFailed(error.response));
    }
}

function* onFeedbackCategory(data) {
    try {
        const response = yield call(getFeedbackCategory, data);
        yield put(getFeedbackCategorySuccess(response));
    } catch (error) {
        yield put(getFeedbackCategoryFailed(error.response));
    }
}

function* onFeedbackSubmit(data) {
    try {
        const response = yield call(submitFeedback, data);
        yield put(submitFeedbackSuccess(response));
    } catch (error) {
        yield put(submitFeedbackFailed(error.response));
    }
}


function* DashboardSaga() {
    yield takeLatest(GET_STATUS_STATE, onGetStatusState)
    yield takeLatest(GET_COMPLETED_STATES, onCompletedStates)
    yield takeLatest(GET_SCHEDULED_STATES, onScheduledStates)
    yield takeLatest(GET_SALES_DATA, onSalesState)
    yield takeLatest(GET_FEEDBACK_CATEGORY, onFeedbackCategory)
    yield takeLatest(SUBMIT_FEEDBACK, onFeedbackSubmit)
}

export default DashboardSaga