import { GET_COMPLETED_STATES, GET_FEEDBACK_CATEGORY, GET_SALES_DATA, GET_SCHEDULED_STATES, GET_STATUS_STATE, SUBMIT_FEEDBACK } from "./actionTypes"

export const getDashboardStatusStates = () => {
    return {
        type: GET_STATUS_STATE
    }
}

export const getStatusStateSuccess = (payload) => {
    return {
        type: GET_STATUS_STATE + `_SUCCESS`,
        payload: payload
    }
}

export const getStatusStateFailed = (payload) => {
    return {
        type: GET_STATUS_STATE + `_FAILED`,
        payload: payload
    }
}

export const getCompletedStates = (payload) => {
    return {
        type: GET_COMPLETED_STATES,
        payload: payload
    }
}

export const getCompletedStatesSuccess = (payload) => {
    return {
        type: GET_COMPLETED_STATES + `_SUCCESS`,
        payload: payload
    }
}

export const getCompletedStatesFailed = (payload) => {
    return {
        type: GET_COMPLETED_STATES + `_FAILED`,
        payload: payload
    }
}

export const getScheduledStatesData = (payload) => {
    return {
        type: GET_SCHEDULED_STATES,
        payload: payload
    }
}

export const getScheduledStatesSuccess = (payload) => {
    return {
        type: GET_SCHEDULED_STATES + `_SUCCESS`,
        payload: payload
    }
}

export const getScheduledStatesFailed = (payload) => {
    return {
        type: GET_SCHEDULED_STATES + `_FAILED`,
        payload: payload
    }
}

export const submitFeedback = (payload) => {
    return {
        type: SUBMIT_FEEDBACK,
        payload: payload
    }
}

export const submitFeedbackSuccess = (payload) => {
    return {
        type: SUBMIT_FEEDBACK + `_SUCCESS`,
        payload: payload
    }
}

export const submitFeedbackFailed = (payload) => {
    return {
        type: SUBMIT_FEEDBACK + `_FAILED`,
        payload: payload
    }
}

export const getSalesChartData = (payload) => {
    return {
        type: GET_SALES_DATA,
        payload: payload
    }
}

export const getSalesChartSuccess = (payload) => {
    return {
        type: GET_SALES_DATA + `_SUCCESS`,
        payload: payload
    }
}

export const getSalesChartFailed = (payload) => {
    return {
        type: GET_SALES_DATA + `_FAILED`,
        payload: payload
    }
}

export const getFeedbackCategory = () => {
    return {
        type: GET_FEEDBACK_CATEGORY
    }
}

export const getFeedbackCategorySuccess = (payload) => {
    return {
        type: GET_FEEDBACK_CATEGORY + `_SUCCESS`,
        payload: payload
    }
}

export const getFeedbackCategoryFailed = (payload) => {
    return {
        type: GET_FEEDBACK_CATEGORY + `_FAILED`,
        payload: payload
    }
}