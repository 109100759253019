import { createApiClient } from '@oneblinktech-org/helios-base';

const apiUrl = process.env.REACT_APP_API_URL;
const { get, post, put, remove } = createApiClient({ apiUrl });

export function getStatusState() {
    const token = localStorage.getItem('h-access_token')

    return get('/dashboard/statusStates', {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}

export function getCompletedStates(data) {
    const token = localStorage.getItem('h-access_token')

    return get(`/dashboard/jobCompletedStates?filter=${data.payload}`, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}

export function getScheduledStates(data) {
    const token = localStorage.getItem('h-access_token')

    return get(`/dashboard/jobScheduledStates?filter=${data.payload}`, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}

export function getSales(data) {
    const token = localStorage.getItem('h-access_token')

    return get(`/dashboard/yearlyStates?year=${data.payload}`, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}

export function getFeedbackCategory() {
    const token = localStorage.getItem('h-access_token')

    return get(`/feedback/category`, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}

export function submitFeedback(data) {
    const token = localStorage.getItem('h-access_token')

    return post(`/feedback`, data.payload, {
        "Authorization": `Bearer ` + token
    }).then(result => {
        return result
    }).catch(e => {
        throw e
    })
}