import React, { useEffect, useState } from 'react'

function TitleWithSelection({ title = '', children = null }) {
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [startPostition, setStartPosition] = useState(0)

    useEffect(() => {
        updatePosition();
        window.addEventListener("resize", updatePosition);
        return () => window.removeEventListener("resize", updatePosition);
    }, [isOpen])

    const updatePosition = () => {
        const arrowIcon = document.querySelector(".img-arrow-down");
        if (arrowIcon) {
            const rect = arrowIcon.getBoundingClientRect();
            setPosition({ top: rect.bottom, left: rect.left });
        }

        const startPostition = document.querySelector(".title-selection");
        if (startPostition) {
            const newRect = startPostition.getBoundingClientRect();
            setStartPosition(newRect.left)
        }
    };

    const handleClick = (event) => {
        event.stopPropagation()
        setIsOpen(!isOpen);
    };

    return (
        <div className='dropdown-container-header'>
            <div className='title-selection' onClick={handleClick}>
                <h5 className="title">{title}</h5>
                <img className='img-arrow-down' src={require('../assets/images/arrow-down.svg').default} alt='' />
            </div>

            {isOpen && (
                <div className="dropdown-menu" style={{ "--arrow-left": position.left - 6 + "px", "--arrow-top": position.top + 11 + "px" }} onClick={() => setIsOpen(false)} >
                    <div className="toggle-container" style={{ marginTop: position.top + 3, marginLeft: startPostition > 0 ? startPostition : position.left - 100 }}
                        onClick={(e) => e.stopPropagation()}>
                        {children}
                    </div>
                </div>
            )}
        </div>
    )
}

export default TitleWithSelection