import React, { useState, useEffect, useRef } from 'react'
import {useHistory, Link, useParams} from 'react-router-dom'
import { Switch, Table, Space, Pagination, Tooltip, Skeleton } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Input } from 'reactstrap'
import { OutlinedButton, FilledBrightButton } from '@oneblinktech-org/helios-base'
import { plus } from '../../fakeData/svgFiles.js'
import moment from 'moment'
import { initializePusher, unbindPusher } from '../../pusherManager.js'
import { getCookie } from '../../helpers/globals.js'

const DEFAULT_PAGE_SIZE = 20

const CustomTable = (props) => {
    const history = useHistory()
    const [columns, setColumns] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [archived, setArchived] = useState(JSON.parse(localStorage.getItem('archivedJobs')) ?? false)
    const [search, setSearch] = useState(localStorage?.getItem("search_job") ?? "")
    const [selectedJob, setSelectedJob] = useState({})
    const params = {limit: DEFAULT_PAGE_SIZE, pageNum: currentPage, searchText: search };
    const inputRef = useRef()
    const { jobs } = props
    const orgId = getCookie('active_organization_id')

    let actionColumn = {};
    if(!props.hideActionColumn) {
        actionColumn = {
            title: 'Action',
            key: 'operation',
            dataIndex: 'action',
            align: 'center',
            render: (text, record) => (
                <div>
                    <Tooltip title="Edit">
                        <Link to={{pathname: props.route + "/edit/" + record.id}} className="mr-1 del-icon"><EditOutlined /></Link>
                    </Tooltip>
                    <Tooltip title="Delete" >
                        <DeleteOutlined onClick={() => props.deleteRecord(record.id)} />
                    </Tooltip>
                </div>
            ),
        }
    }
    useEffect(() => {
        setColumns(
            [
                ...props.columns,
                actionColumn,
            ]
        )
    }, [])

    useEffect(() => {
            initializePusher("product-board", "job-event", async (data) => {
                if (data.event === "card changed" && data?.orgId?.toString() === orgId?.toString()) {
                    const searchJob = await localStorage.getItem("search_job") ?? "";
                    setSearch(searchJob);
                    props.getData({ ...params, archived: archived })
                }
                if (data.event === "job file changed" && data?.orgId?.toString() === orgId?.toString()) {
                    if (data?.value && selectedJob) {
                        props.debouncedGetJobVehicleImgs(data?.value);
                    }
                }
            });
            initializePusher("organizations", "choose-event", async (data) => {
                if (data?.orgId?.toString() === orgId?.toString()) {
                    const searchJob = await localStorage.getItem("search_job") ?? "";
                    setSearch(searchJob);
                    await props.getData({ ...params, archived: archived })
                }
                if (data.event === "selected" && data?.orgId) {
                    const searchJob = await localStorage.getItem("search_job") ?? "";
                    setSearch(searchJob);
                    await props.getData({ ...params, archived: archived })
                }
            });
    
            return () => {
                unbindPusher("product-board", "job-event")
                unbindPusher("organizations", "choose-event")
            }
    }, [archived, currentPage, orgId, params, props])
    
    useEffect(() => {
        props.getData({ ...params, archived: archived })
    }, [archived, currentPage])

    const onTableChange = (pagination, filters, sorter, extra) => {
    }

    const onPagiChange = (pageNum) => {
        setCurrentPage(pageNum)
        params.pageNum = pageNum - 1;
        props.getData({ ...params, archived: archived })
    }

    const onSwitch = (checked) => {
    }

    const searchData = (e) => {
        params.searchText = e.target.value;
        localStorage.setItem("search_job", e.target.value)
        setSearch(e.target.value)
        if (e.target.value?.length >= 3){
            setTimeout(() => {
                props.getData({ ...params, archived: archived, pageNum: 1 }) // send page number 1 in search
            }, 100);
        } else if(e.target.value.length === 0) {
            props.getData({ ...params, archived: archived })
        }
    }

    async function exportCSV() {
        try{
        const data = await jobs?.data?.map((item) => {
            return ({
                "RO Number": item?.ro_number,
                "Customer": `${item?.customer?.firstname} ${item?.customer?.lastname}`,
                "Vehicle": `${item?.vehicle?.year ?? ''} ${item.vehicle?.make?.name ?? ''} ${item?.vehicle?.model ?? ''} ${item.vehicle?.vehicle_color ? item.vehicle?.vehicle_color : ''}`,
                "Status": item.status_from_organization == null ? (item?.plan_date == null ? 'Unscheduled' : 'Scheduled') : item.status_from_organization?.name,
                "Assigned To": item.assigned_to_user ? item.assigned_to_user?.firstname : '' + ' ' + item.assigned_to_user ? item.assigned_to_user?.lastname : '',
                "Last updated": moment(item?.updated_at).format("MMM DD Y h:mm A")
            })
        })

        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(',')); // Add headers

        data.forEach((row) => {
            const values = headers.map((header) => row[header]);
            csvRows.push(values.join(','));
        });

        const csvData = csvRows.join('\n');
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = 'jobs.csv';
        document.body.appendChild(a);
        a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <div className="header-job pb-3">
                <h5 className="title">Job List</h5>
                <div className='d-flex gap-2 align-items-center order-1 order-md-2 justify-content-between right-content' >
                    <div id={`job_new`} className='job-item contents' >
                        <FilledBrightButton
                            svgFile={plus}
                            title="New Job"
                            clickEvent={(e) => {
                                props.clearOpenJob()
                                props.setIsNewJob(true)
                            }}
                            className="mr-2"
                        />  
                    </div>
                    <div className='d-flex gap-2 align-items-center'>
                        <label className="mb-0">Show Archived</label>
                        <Switch checked={archived} onChange={(checked) => setArchived(checked)} />
                    </div>
                </div>
            </div>
            <div className='jobs-container'>
                <div className='search-box'>
                    <div className='search-text'>
                        <img style={{ display: search !== "" ? 'none' : 'flex' }} className="search-icon" src={require('../../assets/images/search.svg').default} alt='search' />
                        <div style={{ display: search !== "" ? 'flex' : 'none' }} onClick={() => {
                            localStorage.setItem("search_job", '')
                            setSearch("")
                            props.getData({ ...params, searchText: '', archived: archived })
                        }}>
                            <img className="close-icon" src={require('../../assets/images/close_fill.svg').default} alt='close' />
                        </div>
                        <input
                            ref={inputRef}
                            className='input'
                            placeholder='Search for anything in Jobs...    job id, customer name or car brand ..'
                            onChange={searchData}
                            value={search}
                        />
                        <div style={{ display: search !== "" ? 'flex' : 'none' }} className='focus-line' />
                    </div>
                    {Object.keys(props?.data)?.length > 0 &&
                        <div className='btn-export' onClick={() => exportCSV()}>
                            <img className="export-icon" src={require('../../assets/images/Upload.svg').default} alt='close' />
                            <span className='export-text' >EXPORT CSV</span>
                        </div>
                    }
                </div>
                <Table 
                    columns={columns}
                    dataSource={props.data}
                    rowKey={record => record.id}
                    align="center" 
                    pagination={{position: ['none'], pageSize: props.data.length}} 
                    onChange={onTableChange}
                    loading={props.loading}
                    className="pb-2"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                                setSelectedJob(record)
                                props.onOpenJob(record)
                                props.setIsNewJob(false)
                            },
                            id: `job_${record.id}`,
                            className: 'job-item'
                        };
                    }}
                    locale={{
                        emptyText: () => props.loading ?
                            <Skeleton active paragraph={{ rows: 15, width: '100%' }} /> : 'No Data'
                    }}
                />
                {jobs?.total > 0 &&
                    <div className='footer' >
                        <button
                            className='first-last'
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(1)}>First</button>
                        <div className='flex gap-3'>
                            <Pagination
                                total={jobs?.total}
                                current={currentPage}
                                defaultPageSize={jobs?.per_page}
                                onChange={(page) => setCurrentPage(page)} />
                        </div>
                        <button
                            class="first-last"
                            disabled={currentPage === jobs?.last_page}
                            onClick={() => setCurrentPage(jobs?.last_page)}>Last</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default CustomTable