import React, { useEffect, useState } from 'react'

function Segmented({ options = [], onChange = null, comparison = false, selected = null }) {
    const [selectedIndex, setSelectedIndex] = useState(-1);

    useEffect(() => {
        if (selected)
            setSelectedIndex(options.findIndex(i => i.slug === selected))
    }, [selected])

    return (
        <div className="segmented-control">
            {selectedIndex !== -1 &&
                <div
                    className="indicator"
                    style={{
                        width: `${100 / options.length}%`,
                        transform: `translateX(${selectedIndex * 100}%)`,
                    }}
                />
            }
            {comparison && selectedIndex !== -1 &&
                <div
                    className="light-active"
                    style={{
                        width: `${(100 / options.length) * 2}%`,
                        transform: `translateX(${selectedIndex}%)`,
                    }}
                />
            }
            {options.map((option, index) => (
                <button
                    key={index}
                    className={`segment ${index === selectedIndex ? "active" : ""}${comparison && index !== selectedIndex ? 'second-active' : ''}`}
                    onClick={() => {
                        setSelectedIndex(index)
                        onChange(option.slug)
                    }}
                >
                    {option.label}
                </button>
            ))}
        </div>
    )
}

export default Segmented