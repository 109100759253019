import { GET_COMPLETED_STATES, GET_FEEDBACK_CATEGORY, GET_SALES_DATA, GET_SCHEDULED_STATES, GET_STATUS_STATE, SUBMIT_FEEDBACK } from "./actionTypes";
import { notification } from 'antd';

const initialState = {
    statusState: {
        loading: false,
        errors: {},
        message: "",
        data: []
    },
    completedStates: {
        loading: false,
        errors: {},
        message: "",
        data: {}
    },
    scheduledStates: {
        loading: false,
        errors: {},
        message: "",
        data: {}
    },
    feedback: {
        loading: false,
        errors: {},
        message: "",
        data: {},
        submitted: false
    },
    salesChart: {
        loading: false,
        errors: {},
        data: {},
        message: ""
    },
    feedbackCategory: {
        loading: false,
        errors: {},
        data: [],
        message: ""
    }
}

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STATUS_STATE:
            state = {
                ...state,
                statusState: {
                    ...state.statusState,
                    loading: true
                }
            }
            break
        case GET_STATUS_STATE + `_SUCCESS`:
            state = {
                ...state,
                statusState: {
                    ...state.statusState,
                    data: action.payload.data,
                    loading: false
                }
            }
            break
        case GET_STATUS_STATE + `_FAILED`:
            state = {
                ...state,
                statusState: {
                    ...state.statusState,
                    loading: false,
                    error: action.payload,
                }
            }
            break

        case GET_COMPLETED_STATES:
            state = {
                ...state,
                completedStates: {
                    ...state.statusState,
                    loading: true
                }
            }
            break
        case GET_COMPLETED_STATES + `_SUCCESS`:
            state = {
                ...state,
                completedStates: {
                    ...state.statusState,
                    data: action.payload.data,
                    loading: false
                }
            }
            break
        case GET_COMPLETED_STATES + `_FAILED`:
            state = {
                ...state,
                completedStates: {
                    ...state.statusState,
                    loading: false,
                    error: action.payload,
                }
            }
            break

        case GET_SCHEDULED_STATES:
            state = {
                ...state,
                scheduledStates: {
                    ...state.statusState,
                    loading: true
                }
            }
            break
        case GET_SCHEDULED_STATES + `_SUCCESS`:
            state = {
                ...state,
                scheduledStates: {
                    ...state.statusState,
                    data: action.payload.data,
                    loading: false
                }
            }
            break
        case GET_SCHEDULED_STATES + `_FAILED`:
            state = {
                ...state,
                scheduledStates: {
                    ...state.statusState,
                    loading: false,
                    error: action.payload,
                }
            }
            break

        case SUBMIT_FEEDBACK:
            state = {
                ...state,
                feedback: {
                    ...state.feedback,
                    loading: true
                }
            }
            break
        case SUBMIT_FEEDBACK + `_SUCCESS`:
            state = {
                ...state,
                feedback: {
                    ...state.feedback,
                    loading: false,
                    data: action.payload,
                    submitted: true
                }
            }
            break
        case SUBMIT_FEEDBACK + `_FAILED`:
            notification.open({ type: 'error', message: 'Something went wrong! Try again.' })
            state = {
                ...state,
                feedback: {
                    ...state.feedback,
                    loading: false,
                    errors: action.payload.data?.message,
                    submitted: false
                }
            }
            break

        case GET_FEEDBACK_CATEGORY:
            state = {
                ...state,
                feedbackCategory: {
                    ...state.feedback,
                    loading: true,
                }
            }
            break
        case GET_FEEDBACK_CATEGORY + `_SUCCESS`:
            state = {
                ...state,
                feedbackCategory: {
                    ...state.feedback,
                    loading: false,
                    data: Object.values(action.payload)?.map((i, index) => { return { label: i, slug: Object.keys(action.payload)[index] } })
                }
            }
            break
        case GET_FEEDBACK_CATEGORY + `_FAILED`:
            state = {
                ...state,
                feedbackCategory: {
                    ...state.feedback,
                    loading: false,
                    errors: action.payload
                }
            }
            break

        case GET_SALES_DATA:
            state = {
                ...state,
                salesChart: {
                    ...state.salesChart,
                    loading: true,
                    data: {}
                }
            }
            break
        case GET_SALES_DATA + `_SUCCESS`:
            state = {
                ...state,
                salesChart: {
                    ...state.salesChart,
                    loading: false,
                    data: action.payload.data
                }
            }
            break
        case GET_SALES_DATA + `_FAILED`:
            state = {
                ...state,
                salesChart: {
                    ...state.salesChart,
                    loading: false,
                    errors: action.payload
                }
            }
            break
        default:
            state = { ...state };
            break;
    }
    return state;
}
export default DashboardReducer