import React, { useEffect, useState } from 'react'
import { Switch } from 'antd'

function SetDefaultPage({ routeName = '', checked = false }) {
    const [isChecked, setChecked] = useState(checked)

    const handleChecked = async (checked, e) => {
        e.stopPropagation();
        setChecked(checked)
        if (checked)
            localStorage.setItem('defaultLandingPage', routeName)
        else if (!checked && isChecked)
            localStorage.removeItem('defaultLandingPage')
    }

    return (
        <>
            <Switch
                checked={isChecked}
                onChange={(checked, e) => handleChecked(checked, e)} />
            <span className="text-default" onClick={(e) => handleChecked(!isChecked, e)} >Make this my default page </span>
        </>
    )
}

export default SetDefaultPage